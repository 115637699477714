import Alarm from "components/alarm";
import Layout from 'components/layout';
import Head from 'next/head';
import { get } from 'api';
import moment from "moment";




export default function ClockTypes({ page, faq, clock_type, _q, _url, baseUrl, raw_url, subdomain, lang_key }) {



  const q = _q ? JSON.parse(_q) : null;
  let title, meta_keywords, meta_description, page_content;
  if (page) {
    const json_page = JSON.parse(page);
    title = json_page?.content['0']?.title;
    meta_keywords = json_page?.content['0']?.meta_keywords;
    meta_description = json_page?.content['0']?.meta_description;
    page_content = json_page?.content['0']?.page_content;

  }
  const defaultAlarm = q ? moment(q?.datetimer) : null;



  return (
    <Layout raw_url={raw_url} subdomain={'alarm'} lang_key={lang_key} >
      <Head>
        <title>{title}</title>
        <meta name="description" content={meta_description} />
        <meta name="keywords" content={meta_keywords} />

        {q && <>
          <meta property="og:image:width" content="523" />
          <meta property="og:image:height" content="236" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={_url} />
          <meta property="og:title" content={q?.title} />
          {defaultAlarm && <meta property="og:description" content={defaultAlarm.format('DD MMMM YYYY')} />}
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:secure" content={q?.bg?.name ? `${baseUrl}/images/bgimagesmy/${q?.bg?.name}_523_236.jpg` : ''} />
          <meta property="og:image" content={q?.bg?.name ? `${baseUrl}/images/bgimagesmy/${q?.bg?.name}_523_236.jpg` : ''} />
        </>
        }
      </Head>


      {subdomain === 'alarm' && <Alarm lang_code={lang_key} faq={faq} page_content={page_content} />}


    </Layout>
  )

}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function getServerSideProps(appContext) {
  const subdomain = 'alarm';
  const lang_key = appContext.req.headers.host.split('.')[0] === 'timertone' ? 'en' : appContext.req.headers.host.split('.')[0];
  // 'en';

  const proto =
    appContext.req.headers["x-forwarded-proto"] || appContext.req.connection.encrypted
      ? "https"
      : "http";
  const baseUrl = `${proto}://${appContext.req.headers.host}`;
  const _url = `${baseUrl}${appContext.req.url}`;

  const page = await get(`public/page?name=${capitalizeFirstLetter(subdomain)}&lang=${lang_key}`);



  return {
    props: {
      raw_url: appContext.req.headers.host,
      subdomain: subdomain,
      lang_key: lang_key,
      _q: appContext.query._q || null,
      baseUrl: baseUrl,
      _url: _url,
      page: JSON.stringify(page.data.payload.page),
      faq: JSON.stringify(page.data.payload.faq),

    }
  }

}

